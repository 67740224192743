import React from "react";
import {CloseButton,Dispatcher,ReCaptchaLoop} from "constructor-agraphe";
import "../style/style_pages/accueil.scss";
import * as constructorData from "../../constructor/_constructor-index.mjs";
import * as components from "../components/_components-index.js";


export default function Accueil () {

  const componentName = "Accueil";

  return (
    <Dispatcher 
      constructorData={constructorData}
      componentName={componentName}
      components={components}
    />
  )
}
